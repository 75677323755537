import { useSignUpStore } from '../stores/sign-up';
import { defineNuxtRouteMiddleware, navigateTo } from 'nuxt/app';

export default defineNuxtRouteMiddleware(() => {
  const signUpStore = useSignUpStore();

  if (!signUpStore.$state.isSignUpAvailable) {
    return navigateTo({ path: '/new-sign-up' });
  }
});
